import React from "react"

import WebsiteLayout from '../components/site/website_layout'
import Form from '../components/site/form'

export default () => (
  <WebsiteLayout>
    <Form />
  </WebsiteLayout>
);
