import React from "react"

import Banner from "./banner"
import routes from "../../services/routes"

// See https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175

export default () =>{

    var url = 'https://script.google.com/macros/s/AKfycbzG2xjbdYm1bM9N52AdVa3p3ApbRYdfRZMXbFeuR-JjlbTK279_/exec'


    // from https://www.learnwithjason.dev/blog/get-form-values-as-json/
    const formToJSON = function(elements) {
        // Not my favorite syntax but ok 
        return [].reduce.call(elements, (data, element) => {
            if (element.name)
                data[element.name] = element.value;
            return data;
         }, {});
     }
    // https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
    const serialize = function(obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }

    // https://medium.com/@dmccoy/how-to-submit-an-html-form-to-google-sheets-without-google-forms-b833952cc175
    const submitForm = async function(e) {
        e.preventDefault()

        var data = formToJSON(document.querySelector("form#test-form").elements)
        console.log("found data", data)
        //~ return
        try {
            var combined_url = url + "?" + serialize(data)
            console.log("Using combined url", url)
            await fetch(combined_url,{ 
                mode: "no-cors",}) // no-cors is required
            console.log("Posting worked!")
            alert("Posting worked!")
        } catch(err) {
            console.log("Got an error", err)
        }
    }

   return (
	<>
		<Banner title="inFORM us! lolz" link={routes.form} />
		<div style={{backgroundColor:"white", margin:"50px", padding:"15px", textAlign:"center"}}>
        {/* If the form has been submitted, show a success page. Else show the form */}
        <form id="test-form">
          
          <div>
            <label>Name</label>
            <input type="text" name="name" placeholder="Name"/>
          </div>

          <div>
            <label>Email</label>
            <input type="text" name="email" placeholder="Email"/>
          </div>

          <div>
            <button type="submit"id="submit-form" onClick={submitForm}>Submit</button>
          </div>

        </form>
		</div>
	</>
);
}
